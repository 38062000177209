import { NGXLogger } from "ngx-logger";
import { authAs, authType, ILayer } from "../definitions";
import { MapController } from "./map-controller";
import { getExMessage } from "./map-utils";


import Control from 'ol/control/Control';
import Overlay from 'ol/Overlay';
import Geolocation from 'ol/Geolocation';
import * as olCoordinate from 'ol/coordinate';
import MousePosition from "ol/control/MousePosition";


export class MapCtrlTools {

    constructor(private mapCtrl: MapController, private $log : NGXLogger){

    }

    public addIntegrateLandsatHotspotButton() {
        this.mapCtrl.integrateLandsatHotspotButton = document.createElement('button');
        this.mapCtrl.integrateLandsatHotspotButton.innerHTML = '<i class="fas fa-temperature-high"></i>';
        this.mapCtrl.integrateLandsatHotspotButton.title = 'activează/dezactivează Procesare hotspot/coolspot date satelitare';
        $(this.mapCtrl.integrateLandsatHotspotButton).tooltip({placement: "right"});
        let element = document.createElement('div');
        element.className = 'integrate-landsat-hotspot-button ol-unselectable ol-control';
        element.appendChild(this.mapCtrl.integrateLandsatHotspotButton);

        this.mapCtrl.integrateLandsatHotspotButtonCtrl = new Control({
            element: element
        });

        this.mapCtrl.integrateLandsatHotspotButton.addEventListener('click', (event) => { this.onClickIntegrateLandsatHotspotButton(event) });
        this.mapCtrl.map.addControl(this.mapCtrl.integrateLandsatHotspotButtonCtrl);
    }

    public onClickIntegrateLandsatHotspotButton(event: any){
        if ( this.mapCtrl.integrateLandsatHotspotButtonStateOn === false
            && this.mapCtrl.toolboxStateIsOn()){
            this.$log.log("toolbox activ");
            return;
        }
        //
        this.mapCtrl.integrateLandsatHotspotButtonStateOn = !this.mapCtrl.integrateLandsatHotspotButtonStateOn;
        //
        if (this.mapCtrl.integrateLandsatHotspotButtonStateOn){
            //
            if (this.mapCtrl.integrateLandsatHotspotData.funcInit){
                this.mapCtrl.integrateLandsatHotspotData.funcInit();
            }
        } else {
            //
            if (this.mapCtrl.integrateLandsatHotspotData.funcClear){
                this.mapCtrl.integrateLandsatHotspotData.funcClear();
            }
        }
    }

    public showIntegrateLandsatHotspotButtonIfHasAccess() {
        try {
            //check
            this.mapCtrl.integrateLandsatHotspotButtonStateVisible = this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.control_integrate_landsat_hotspot_button_visible, authType.object);
            let tmpdefaut = this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.control_integrate_landsat_hotspot_default_active, authType.object);
            if (this.mapCtrl.integrateLandsatHotspotButtonCtrl) {
                if (this.mapCtrl.integrateLandsatHotspotButtonStateVisible) {
                    $(this.mapCtrl.integrateLandsatHotspotButtonCtrl["element"]).removeClass('greentop-button-hide');
                    this.mapCtrl.integrateLandsatHotspotButtonStateOn = tmpdefaut;
                    if (this.mapCtrl.integrateLandsatHotspotButtonStateOn) {
                        //this.initIntegrateLandsatHotspotTool();
                        if (this.mapCtrl.integrateLandsatHotspotData.funcInit){
                            this.mapCtrl.integrateLandsatHotspotData.funcInit();
                        }
                        
                    } else {
                        //this.clearIntegrateLandsatHotspotTool();
                        if (this.mapCtrl.integrateLandsatHotspotData.funcClear){
                            this.mapCtrl.integrateLandsatHotspotData.funcClear();
                        }
                    }
                } else {
                    $(this.mapCtrl.integrateLandsatHotspotButtonCtrl["element"]).addClass('greentop-button-hide');
                    this.mapCtrl.integrateLandsatHotspotButtonStateOn = false;
                }
            }
        } catch (error) {
            this.$log.error("eroare afisare buton control integrate hotspot ");
        }

    }

    public addIntegrateLandsatDownloadButton() {
        this.mapCtrl.integrateLandsatDwonloadButton = document.createElement('button');
        this.mapCtrl.integrateLandsatDwonloadButton.innerHTML = '<i class="fas fa-arrow-alt-circle-down"></i>';
        this.mapCtrl.integrateLandsatDwonloadButton.title = 'activează/dezactivează Planificare descarcare date satelitare Integrate';
        $(this.mapCtrl.integrateLandsatDwonloadButton).tooltip({placement: "right"});
        let element = document.createElement('div');
        element.className = 'integrate-landsat-download-button ol-unselectable ol-control';
        element.appendChild(this.mapCtrl.integrateLandsatDwonloadButton);

        this.mapCtrl.integrateLandsatDwonloadButtonCtrl = new Control({
            element: element
        });

        this.mapCtrl.integrateLandsatDwonloadButton.addEventListener('click', (event) => { this.onClickintegrateLandsatDownloadButton(event) });
        this.mapCtrl.map.addControl(this.mapCtrl.integrateLandsatDwonloadButtonCtrl);
    }

    public onClickintegrateLandsatDownloadButton(event: any){
        if ( this.mapCtrl.integrateLandsatDwonloadButtonStateOn === false
            && this.mapCtrl.toolboxStateIsOn()){
            this.$log.log("toolbox activ");
            return;
        }
        //
        this.mapCtrl.integrateLandsatDwonloadButtonStateOn = !this.mapCtrl.integrateLandsatDwonloadButtonStateOn;
        // 
        if (this.mapCtrl.integrateLandsatDwonloadButtonStateOn){
            if (this.mapCtrl.integrateLandsatDownloadData.funcInit){
                this.mapCtrl.integrateLandsatDownloadData.funcInit();
            }
        } else {
            if (this.mapCtrl.integrateLandsatDownloadData.funcClear){
                this.mapCtrl.integrateLandsatDownloadData.funcClear();
            }
        }
    }

    public showIntegrateLandsatDwButtonIfHasAccess(){
        try {
            //check
            this.mapCtrl.integrateLandsatDwonloadButtonStateVisible = this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.control_integrate_landsat_dw_button_visible, authType.object);
            let tmpdefaut = this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.control_integrate_landsat_dw_default_active, authType.object);
            if (this.mapCtrl.integrateLandsatDwonloadButtonCtrl) {
                if (this.mapCtrl.integrateLandsatDwonloadButtonStateVisible ) {
                    $(this.mapCtrl.integrateLandsatDwonloadButtonCtrl["element"]).removeClass('integrate-landsat-button-hide');
                    this.mapCtrl.integrateLandsatDwonloadButtonStateOn = tmpdefaut;
                    if (this.mapCtrl.integrateLandsatDwonloadButtonStateOn){
                        if (this.mapCtrl.integrateLandsatDownloadData.funcInit){
                            this.mapCtrl.integrateLandsatDownloadData.funcInit();
                        }
                    } else {
                        if (this.mapCtrl.integrateLandsatDownloadData.funcClear){
                            this.mapCtrl.integrateLandsatDownloadData.funcClear();
                        }
                    }
                } else {
                    $(this.mapCtrl.integrateLandsatDwonloadButtonCtrl["element"]).addClass('integrate-landsat-button-hide');
                    this.mapCtrl.integrateLandsatDwonloadButtonStateOn = false;
                }
            }
        } catch (error) {
            this.$log.error("eroare afisare buton control integrateLandsat download ");
        }
        
    }
    //
    public addIntegrateLandsatProcIndConexButton() {
        this.mapCtrl.integrateLandsatProcIndConexButton = document.createElement('button');
        this.mapCtrl.integrateLandsatProcIndConexButton.innerHTML = '<i class="fas fa-gopuram"></i>';
        this.mapCtrl.integrateLandsatProcIndConexButton.title = 'activează/dezactivează Procesare indice conex';
        $(this.mapCtrl.integrateLandsatProcIndConexButton).tooltip({placement: "right"});
        let element = document.createElement('div');
        element.className = 'integrate-landsat-prindconex-button ol-unselectable ol-control';
        element.appendChild(this.mapCtrl.integrateLandsatProcIndConexButton);

        this.mapCtrl.integrateLandsatProcIndConexButtonCtrl = new Control({
            element: element
        });

        this.mapCtrl.integrateLandsatProcIndConexButton.addEventListener('click', (event) => { this.onClickintegrateLandsatProcIndConexButton(event) });
        this.mapCtrl.map.addControl(this.mapCtrl.integrateLandsatProcIndConexButtonCtrl);
    }

    public onClickintegrateLandsatProcIndConexButton(event: any){
        if ( this.mapCtrl.integrateLandsatProcIndConexButtonStateOn === false
            && this.mapCtrl.toolboxStateIsOn()){
            this.$log.log("toolbox activ");
            return;
        }
        //
        this.mapCtrl.integrateLandsatProcIndConexButtonStateOn = !this.mapCtrl.integrateLandsatProcIndConexButtonStateOn;
        // 
        if (this.mapCtrl.integrateLandsatProcIndConexButtonStateOn){
            if (this.mapCtrl.integrateLandsatProcIndConexData.funcInit){
                this.mapCtrl.integrateLandsatProcIndConexData.funcInit();
            }
        } else {
            if (this.mapCtrl.integrateLandsatProcIndConexData.funcClear){
                this.mapCtrl.integrateLandsatProcIndConexData.funcClear();
            }
        }
    }

    public showIntegrateLandsatPrButtonIfHasAccess(){
        try {
            //check
            this.mapCtrl.integrateLandsatProcIndConexButtonStateVisible = this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.control_integrate_landsat_prindconex_button_visible, authType.object);
            let tmpdefaut = this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.control_integrate_landsat_prindconex_default_active, authType.object);
            if (this.mapCtrl.integrateLandsatProcIndConexButtonCtrl) {
                if (this.mapCtrl.integrateLandsatProcIndConexButtonStateVisible ) {
                    $(this.mapCtrl.integrateLandsatProcIndConexButtonCtrl["element"]).removeClass('integrate-landsat-button-hide');
                    this.mapCtrl.integrateLandsatProcIndConexButtonStateOn = tmpdefaut;
                    if (this.mapCtrl.integrateLandsatProcIndConexButtonStateOn){
                        if (this.mapCtrl.integrateLandsatProcIndConexData.funcInit){
                            this.mapCtrl.integrateLandsatProcIndConexData.funcInit();
                        }
                    } else {
                        if (this.mapCtrl.integrateLandsatProcIndConexData.funcClear){
                            this.mapCtrl.integrateLandsatProcIndConexData.funcClear();
                        }
                    }
                } else {
                    $(this.mapCtrl.integrateLandsatProcIndConexButtonCtrl["element"]).addClass('integrate-landsat-button-hide');
                    this.mapCtrl.integrateLandsatProcIndConexButtonStateOn = false;
                }
            }
        } catch (error) {
            this.$log.error("eroare afisare buton control integrateLandsat ProcIndConex ");
        }
        
    }

    public addIntegrateLandsatProcDateSezoniereButton() {
        this.mapCtrl.integrateLandsatProcDateSezoniereButton = document.createElement('button');
        this.mapCtrl.integrateLandsatProcDateSezoniereButton.innerHTML = '<i class="fas fa-rainbow"></i>';
        this.mapCtrl.integrateLandsatProcDateSezoniereButton.title = 'activează/dezactivează Procesare date sezoniere';
        $(this.mapCtrl.integrateLandsatProcDateSezoniereButton).tooltip({placement: "right"});
        let element = document.createElement('div');
        element.className = 'integrate-landsat-prdtsez-button ol-unselectable ol-control';
        element.appendChild(this.mapCtrl.integrateLandsatProcDateSezoniereButton);

        this.mapCtrl.integrateLandsatProcDateSezoniereButtonCtrl = new Control({
            element: element
        });

        this.mapCtrl.integrateLandsatProcDateSezoniereButton.addEventListener('click', (event) => { this.onClickintegrateLandsatProcDateSezoniereButton(event) });
        this.mapCtrl.map.addControl(this.mapCtrl.integrateLandsatProcDateSezoniereButtonCtrl);
    }

    public onClickintegrateLandsatProcDateSezoniereButton(event: any){
        if ( this.mapCtrl.integrateLandsatProcDateSezoniereButtonStateOn === false
            && this.mapCtrl.toolboxStateIsOn()){
            this.$log.log("toolbox activ");
            return;
        }
        //
        this.mapCtrl.integrateLandsatProcDateSezoniereButtonStateOn = !this.mapCtrl.integrateLandsatProcDateSezoniereButtonStateOn;
        // 
        if (this.mapCtrl.integrateLandsatProcDateSezoniereButtonStateOn){
            if (this.mapCtrl.integrateLandsatProcDateSezoniereData.funcInit){
                this.mapCtrl.integrateLandsatProcDateSezoniereData.funcInit();
            }
        } else {
            if (this.mapCtrl.integrateLandsatProcDateSezoniereData.funcClear){
                this.mapCtrl.integrateLandsatProcDateSezoniereData.funcClear();
            }
        }
    }

    public showIntegrateLandsatDateSezoniereButtonIfHasAccess(){
        try {
            //check
            this.mapCtrl.integrateLandsatProcDateSezoniereButtonStateVisible = this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.control_integrate_landsat_prdtsezonier_button_visible, authType.object);
            let tmpdefaut = this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.control_integrate_landsat_prdtsezonier_default_active, authType.object);
            if (this.mapCtrl.integrateLandsatProcDateSezoniereButtonCtrl) {
                if (this.mapCtrl.integrateLandsatProcDateSezoniereButtonStateVisible ) {
                    $(this.mapCtrl.integrateLandsatProcDateSezoniereButtonCtrl["element"]).removeClass('integrate-landsat-button-hide');
                    this.mapCtrl.integrateLandsatProcDateSezoniereButtonStateOn = tmpdefaut;
                    if (this.mapCtrl.integrateLandsatProcDateSezoniereButtonStateOn){
                        if (this.mapCtrl.integrateLandsatProcDateSezoniereData.funcInit){
                            this.mapCtrl.integrateLandsatProcDateSezoniereData.funcInit();
                        }
                    } else {
                        if (this.mapCtrl.integrateLandsatProcDateSezoniereData.funcClear){
                            this.mapCtrl.integrateLandsatProcDateSezoniereData.funcClear();
                        }
                    }
                } else {
                    $(this.mapCtrl.integrateLandsatProcDateSezoniereButtonCtrl["element"]).addClass('integrate-landsat-button-hide');
                    this.mapCtrl.integrateLandsatProcDateSezoniereButtonStateOn = false;
                }
            }
        } catch (error) {
            this.$log.error("eroare afisare buton control integrateLandsat ProcDateSezoniere ");
        }
        
    }
    //
    public addGreentopDownloadButton() {
        this.mapCtrl.greentopDwonloadButton = document.createElement('button');
        this.mapCtrl.greentopDwonloadButton.innerHTML = '<i class="fas fa-arrow-alt-circle-down"></i>';
        this.mapCtrl.greentopDwonloadButton.title = 'activează/dezactivează Planificare descărcare date satelitare';
        $(this.mapCtrl.greentopDwonloadButton).tooltip({placement: "right"});
        let element = document.createElement('div');
        element.className = 'greentop-download-button ol-unselectable ol-control';
        element.appendChild(this.mapCtrl.greentopDwonloadButton);

        this.mapCtrl.greentopDwonloadButtonCtrl = new Control({
            element: element
        });

        this.mapCtrl.greentopDwonloadButton.addEventListener('click', (event) => { this.onClickgreentopDownloadButton(event) });
        this.mapCtrl.map.addControl(this.mapCtrl.greentopDwonloadButtonCtrl);
    }
    //
    public onClickgreentopDownloadButton(event: any){
        if ( this.mapCtrl.greentopDwonloadButtonStateOn === false
            && this.mapCtrl.toolboxStateIsOn()){
            this.$log.log("toolbox activ");
            return;
        }
        if (this.mapCtrl.greentopClassifyMinimizeStateOn === true){
            this.$log.log("tool clasificare activ");
            return;
        }
        //
        this.mapCtrl.greentopDwonloadButtonStateOn = !this.mapCtrl.greentopDwonloadButtonStateOn;
        // todo
        if (this.mapCtrl.greentopDwonloadButtonStateOn){
            if (this.mapCtrl.greentopDownloadData.funcInit){
                this.mapCtrl.greentopDownloadData.funcInit();
            }
        } else {
            if (this.mapCtrl.greentopDownloadData.funcClear){
                this.mapCtrl.greentopDownloadData.funcClear();
            }
        }
    }
    //
    public showGreentopDwButtonIfHasAccess(){
        try {
            //check
            this.mapCtrl.greentopDwonloadButtonStateVisible = this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.control_grentop_dw_button_visible, authType.object);
            let tmpdefaut = this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.control_grentop_dw_default_active, authType.object);
            if (this.mapCtrl.greentopDwonloadButtonCtrl) {
                if (this.mapCtrl.greentopDwonloadButtonStateVisible ) {
                    $(this.mapCtrl.greentopDwonloadButtonCtrl["element"]).removeClass('greentop-button-hide');
                    this.mapCtrl.greentopDwonloadButtonStateOn = tmpdefaut;
                    if (this.mapCtrl.greentopDwonloadButtonStateOn){
                        if (this.mapCtrl.greentopDownloadData.funcInit){
                            this.mapCtrl.greentopDownloadData.funcInit();
                        }
                    } else {
                        if (this.mapCtrl.greentopDownloadData.funcClear){
                            this.mapCtrl.greentopDownloadData.funcClear();
                        }
                    }
                } else {
                    $(this.mapCtrl.greentopDwonloadButtonCtrl["element"]).addClass('greentop-button-hide');
                    this.mapCtrl.greentopDwonloadButtonStateOn = false;
                }
            }
        } catch (error) {
            this.$log.error("eroare afisare buton control greentop download ");
        }
        
    }

    //
    public addGreentopTrainingButton() {
        this.mapCtrl.greentopTrainingButton = document.createElement('button');
        this.mapCtrl.greentopTrainingButton.innerHTML = '<i class="fas fa-brain"></i>';
        this.mapCtrl.greentopTrainingButton.title = 'activează/dezactivează Planificare antrenament model clasificare';
        $(this.mapCtrl.greentopTrainingButton).tooltip({placement: "right"});
        let element = document.createElement('div');
        element.className = 'greentop-training-button ol-unselectable ol-control';
        element.appendChild(this.mapCtrl.greentopTrainingButton);

        this.mapCtrl.greentopTrainingButtonCtrl = new Control({
            element: element
        });

        this.mapCtrl.greentopTrainingButton.addEventListener('click', (event) => { this.onClickgreentopTrainingButton(event) });
        this.mapCtrl.map.addControl(this.mapCtrl.greentopTrainingButtonCtrl);
    }
    //
    public onClickgreentopTrainingButton(event: any) {
        if (this.mapCtrl.greentopTrainingButtonStateOn === false
            && this.mapCtrl.toolboxStateIsOn()) {
            this.$log.log("toolbox activ");
            return;
        }
        if (this.mapCtrl.greentopClassifyMinimizeStateOn === true){
            this.$log.log("tool clasificare activ");
            return;
        }
        //
        this.mapCtrl.greentopTrainingButtonStateOn = !this.mapCtrl.greentopTrainingButtonStateOn;
        // 
        if (this.mapCtrl.greentopTrainingButtonStateOn) {
            if (this.mapCtrl.greentopTrainingData.funcInit){
                this.mapCtrl.greentopTrainingData.funcInit();
            }
        } else {
            if (this.mapCtrl.greentopTrainingData.funcClear){
                this.mapCtrl.greentopTrainingData.funcClear();
            }
        }
    }
    //
    public showGreentopTrButtonIfHasAccess() {
        try {
            //check
            this.mapCtrl.greentopTrainingButtonStateVisible = this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.control_grentop_tr_button_visible, authType.object);
            let tmpdefaut = this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.control_grentop_tr_default_active, authType.object);
            if (this.mapCtrl.greentopTrainingButtonCtrl) {
                if (this.mapCtrl.greentopTrainingButtonStateVisible) {
                    $(this.mapCtrl.greentopTrainingButtonCtrl["element"]).removeClass('greentop-button-hide');
                    this.mapCtrl.greentopTrainingButtonStateOn = tmpdefaut;
                    if (this.mapCtrl.greentopTrainingButtonStateOn) {
                        if (this.mapCtrl.greentopTrainingData.funcInit){
                            this.mapCtrl.greentopTrainingData.funcInit();
                        }
                    } else {
                        if (this.mapCtrl.greentopTrainingData.funcClear){
                            this.mapCtrl.greentopTrainingData.funcClear();
                        }
                    }
                } else {
                    $(this.mapCtrl.greentopTrainingButtonCtrl["element"]).addClass('greentop-button-hide');
                    this.mapCtrl.greentopTrainingButtonStateOn = false;
                }
            }
        } catch (error) {
            this.$log.error("eroare afisare buton control greentop training ");
        }

    }
    //
    public addGreentopClassifyButton() {
        this.mapCtrl.greentopClassifyButton = document.createElement('button');
        this.mapCtrl.greentopClassifyButton.innerHTML = '<i class="fas fa-globe-europe"></i>';
        this.mapCtrl.greentopClassifyButton.title = 'activează/dezactivează Planificare clasificare date satelitare';
        $(this.mapCtrl.greentopClassifyButton).tooltip({placement: "right"});
        let element = document.createElement('div');
        element.className = 'greentop-classify-button ol-unselectable ol-control';
        element.appendChild(this.mapCtrl.greentopClassifyButton);

        this.mapCtrl.greentopClassifyButtonCtrl = new Control({
            element: element
        });

        this.mapCtrl.greentopClassifyButton.addEventListener('click', (event) => { this.onClickgreentopClassifyButton(event) });
        this.mapCtrl.map.addControl(this.mapCtrl.greentopClassifyButtonCtrl);
    }

    public onClickgreentopClassifyButton(event: any) {
        if (this.mapCtrl.greentopClassifyButtonStateOn === false
            && this.mapCtrl.toolboxStateIsOn()) {
            this.$log.log("toolbox activ");
            return;
        }
        //
        this.mapCtrl.greentopClassifyButtonStateOn = !this.mapCtrl.greentopClassifyButtonStateOn;
        // todo
        if (this.mapCtrl.greentopClassifyButtonStateOn) {
            if(this.mapCtrl.greentopClassifyMinimizeStateOn === false){
                if (this.mapCtrl.greentopClassifyData.funcInit){
                    this.mapCtrl.greentopClassifyData.funcInit();
                }
            } else {
                this.mapCtrl.greentopClassifyMinimizeStateOn = false;
                $(this.mapCtrl.greentopClassifyButtonCtrl["element"]).removeClass('select-button-on');
            }
        } else {
            if (this.mapCtrl.greentopClassifyData.funcClear){
                this.mapCtrl.greentopClassifyData.funcClear();
            }
        }
    }
    public showGreentopClButtonIfHasAccess() {
        try {
            //check
            this.mapCtrl.greentopClassifyButtonStateVisible = this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.control_grentop_cl_button_visible, authType.object);
            let tmpdefaut = this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.control_grentop_cl_default_active, authType.object);
            if (this.mapCtrl.greentopClassifyButtonCtrl) {
                if (this.mapCtrl.greentopClassifyButtonStateVisible) {
                    $(this.mapCtrl.greentopClassifyButtonCtrl["element"]).removeClass('greentop-button-hide');
                    this.mapCtrl.greentopClassifyButtonStateOn = tmpdefaut;
                    //
                    if (this.mapCtrl.greentopClassifyButtonStateOn) {
                        if (this.mapCtrl.greentopClassifyData.funcInit){
                            this.mapCtrl.greentopClassifyData.funcInit();
                        }
                    } else {
                        if (this.mapCtrl.greentopClassifyData.funcClear){
                            this.mapCtrl.greentopClassifyData.funcClear();
                        }
                    }
                } else {
                    $(this.mapCtrl.greentopClassifyButtonCtrl["element"]).addClass('greentop-button-hide');
                    this.mapCtrl.greentopClassifyButtonStateOn = false;
                }
            }
        } catch (error) {
            this.$log.error("eroare afisare buton control greentop classify ");
        }

    }

    //
    public addPositionMarkerButton() {
        let options = {};
        this.mapCtrl.positionMarkerButton = document.createElement('button');
        this.mapCtrl.positionMarkerButton.innerHTML = '<i class="fas fa-map-marked-alt"></i>';
        this.mapCtrl.positionMarkerButton.title = 'activează/dezactivează căutare locație adresă și localizare gps';
        $(this.mapCtrl.positionMarkerButton).tooltip({placement: "right"});
        let element = document.createElement('div');
        element.className = 'position-button ol-unselectable ol-control';
        element.appendChild(this.mapCtrl.positionMarkerButton);

        this.mapCtrl.positionMarkerButtonCtrl = new Control({
            element: element,
            target: options["target"]
        });

        this.mapCtrl.positionMarkerButton.addEventListener('click', this.onClickPositionMarkerButton);
        this.mapCtrl.map.addControl(this.mapCtrl.positionMarkerButtonCtrl);
    }
    public onClickPositionMarkerButton = (event) => {
        if ( this.mapCtrl.positionButtonStateOn === false
            && this.mapCtrl.toolboxStateIsOn()){
            this.$log.log("toolbox activ");
            return;
        }
        //
        this.mapCtrl.positionButtonStateOn = !this.mapCtrl.positionButtonStateOn;
        //
        if (this.mapCtrl.positionButtonStateOn){
            if (this.mapCtrl.positionData.funcInit){
                this.mapCtrl.positionData.funcInit();
            }
        } else {
            if (this.mapCtrl.positionData.funcClear){
                this.mapCtrl.positionData.funcClear();
            }
        }
        
    }
    public buildPositionMarkerOverlay() {
        this.mapCtrl.positionMarkerOverlay = new Overlay({
            positioning: 'center-center',
            element: document.getElementById('location-marker'),
            stopEvent: false
        });
        this.mapCtrl.geoLocation = new Geolocation({
            projection: this.mapCtrl.map.getView().getProjection()
        });
        this.mapCtrl.geoLocation.on('change:position', () => {
            this.mapCtrl.positionMarkerOverlay.setPosition(this.mapCtrl.geoLocation.getPosition());
            this.mapCtrl.map.getView().setCenter(this.mapCtrl.geoLocation.getPosition());
            this.$log.log("position changed");
        });

    }

    //
    addCtrlMousePosition() {
        if (this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.control_coordinates_visible, authType.object)) {
            let proj = 'EPSG:4326';
            if (this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.control_coordinates_use_map_projection, authType.object)) {
                proj = this.mapCtrl.mapConfig.projection;
            }
            if (this.mapCtrl.mousePositionCtrl != null) {
                try {
                    this.mapCtrl.map.removeControl(this.mapCtrl.mousePositionCtrl);
                } catch (e) {
                    this.$log.error('remove mouse position control error: ', getExMessage(e));
                }

            }
            this.mapCtrl.mousePositionCtrl = new MousePosition({
                coordinateFormat: olCoordinate.createStringXY(5),
                projection: proj
            });
            this.mapCtrl.map.addControl(this.mapCtrl.mousePositionCtrl);
        } else {
            if (this.mapCtrl.mousePositionCtrl != null) {
                try {
                    this.mapCtrl.map.removeControl(this.mapCtrl.mousePositionCtrl);
                } catch (e) {
                    this.$log.error('remove mouse position control error: ' , getExMessage(e));
                }
            }
        }
    }

    //
    //
    public addTranzitButton() {
        this.mapCtrl.tranzitButton = document.createElement('button');
        this.mapCtrl.tranzitButton.innerHTML = '<i class="fas fa-route"></i>';
        this.mapCtrl.tranzitButton.title = 'activează/dezactivează Planificare călătorie';
        $(this.mapCtrl.tranzitButton).tooltip({placement: "right"});
        let element = document.createElement('div');
        element.className = 'tranzit-button ol-unselectable ol-control';
        element.appendChild(this.mapCtrl.tranzitButton);

        this.mapCtrl.tranzitButtonCtrl = new Control({
            element: element
        });

        this.mapCtrl.tranzitButton.addEventListener('click', (event) => { this.onClickTranzitButton(event) });
        this.mapCtrl.map.addControl(this.mapCtrl.tranzitButtonCtrl);
    }


    public onClickTranzitButton(event: any){
        if ( this.mapCtrl.tranzitButtonStateOn === false
            && this.mapCtrl.toolboxStateIsOn()){
            this.$log.log("toolbox activ");
            return;
        }
        if (this.mapCtrl.greentopClassifyMinimizeStateOn === true){
            this.$log.log("tool clasificare activ");
            return;
        }
        //
        this.mapCtrl.tranzitButtonStateOn = !this.mapCtrl.tranzitButtonStateOn;
        // todo
        if (this.mapCtrl.tranzitButtonStateOn){
            if (this.mapCtrl.tranzitData.funcInit){
                this.mapCtrl.tranzitData.funcInit();
            }
        } else {
            if (this.mapCtrl.tranzitData.funcClear){
                this.mapCtrl.tranzitData.funcClear();
            }
        }

    }
    //
    public showTranzitButtonIfHasAccess(){
        try {
            //check
            this.mapCtrl.tranzitButtonStateVisible = this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.control_tranzit_button_visible, authType.object);
            let tmpdefaut = this.mapCtrl.userSettingsSrvs.isAuthForResource(authAs.control_tranzit_default_active, authType.object);
            if (this.mapCtrl.tranzitButtonCtrl) {
                if (this.mapCtrl.tranzitButtonStateVisible ) {
                    $(this.mapCtrl.tranzitButtonCtrl["element"]).removeClass('tranz-button-hide');
                    this.mapCtrl.tranzitButtonStateOn = tmpdefaut;
                    setTimeout(()=>{
                        if (this.mapCtrl.tranzitButtonStateOn){
                            if (this.mapCtrl.tranzitData.funcInit){
                                this.mapCtrl.tranzitData.funcInit();
                            }
                        } else {
                            if (this.mapCtrl.tranzitData.funcClear){
                                this.mapCtrl.tranzitData.funcClear();
                            }
                        }
                    },500);
                } else {
                    $(this.mapCtrl.tranzitButtonCtrl["element"]).addClass('tranz-button-hide');
                    this.mapCtrl.tranzitButtonStateOn = false;
                }
            }
        } catch (error) {
            this.$log.error("eroare afisare buton control tranzit ");
        }
        
    }

    public addLegendButton() {
        this.mapCtrl.legendButton = document.createElement('button');
        this.mapCtrl.legendButton.innerHTML = '<i class="fas fa-atlas"></i>';
        this.mapCtrl.legendButton.title = 'activează/dezactivează legendă';
        $(this.mapCtrl.legendButton).tooltip();
        let element = document.createElement('div');
        element.className = 'legend-button ol-unselectable ol-control';
        element.appendChild(this.mapCtrl.legendButton);

        this.mapCtrl.legendButtonCtrl = new Control({
            element: element
        });

        this.mapCtrl.legendButton.addEventListener('click', this.onClickLengendButton);
        this.mapCtrl.map.addControl(this.mapCtrl.legendButtonCtrl);
        this.mapCtrl.legendContent = document.getElementById("legendContent") as HTMLDivElement;
    }
    //
    public onClickLengendButton = (event) => {
        this.mapCtrl.legendButtonStateOn = !this.mapCtrl.legendButtonStateOn;
    }
}