 
 
//
import { NGXLogger } from "ngx-logger";
import { ILayer, authOpt, authType } from "../definitions";
import { MapController } from "./map-controller";
import { getExMessage } from "./map-utils";


import VectorLayer from 'ol/layer/Vector';
import TileLayer from 'ol/layer/Tile';
import  TileWMSSource  from "ol/source/TileWMS";

    export class MapCtrlAnimateTimeRaster {
        private resInterval: any;
        private isInitingFlag = false;
        public constructor(public mapCtrl: MapController, private $log : NGXLogger) {

        };

        public sliderChanged = () => {
            //this.mapCtrl.animate.speed = 0;
        }

        //
        public onAnimateTimeRasterAction = (layer: ILayer, timeList: Array<string>) => {
            try {
                //
                this.mapCtrl.animateTimeRasterData.sourceRasterLayer = layer;
                if (timeList == null) {
                    //
                    this.initloadAnimateData(layer);
                } else {
                    timeList.forEach((strDate) => {
                        if (strDate && strDate.length > 0) {
                            let exists = this.mapCtrl.animateTimeRasterData.steps.filter((item) => item === strDate);
                            if (exists == null || exists.length == 0) {
                                this.mapCtrl.animateTimeRasterData.steps.push(strDate);
                            }
                        }
                    })
                    //
                    this.setAnimateData();
                }
            } catch (reason) {
                this.$log.error("Eroare animare strat temporal ", getExMessage(reason));
                this.animateCancel();
            }
        }

        public setAnimateData(){
            this.isInitingFlag = false;
            this.mapCtrl.animateTimeRasterData.steps.sort();
            this.mapCtrl.animateTimeRasterData.stepValues = [];
            this.initSourceExisting();
            this.setSourceRasterTimeParam(this.mapCtrl.animateTimeRasterData.steps[this.mapCtrl.animateTimeRasterData.index]);
            this.mapCtrl.showMainMenu = false;
            this.mapCtrl.timeRasterAnimate = true;
            this.setInitialSteps();
            //
            this.configAnimate();
        }

        public initloadAnimateData(layer: ILayer) {
            try {
                this.isInitingFlag = true;
                let sourceLayerName = this.mapCtrl.userSettingsSrvs.isAuthForOptionFullInfo(authOpt.animate_time_raster_source, layer.name, authType.layer);
                if (sourceLayerName === undefined || sourceLayerName.descriere === undefined || sourceLayerName.descriere === '') {
                    throw new Error("lipseste nume strat connex");
                }
                // cautam stratul din harta
                let sourceLayer = this.mapCtrl.mapOlFeatures.searchForVectorLayer(sourceLayerName.descriere);
                if (sourceLayer === null) {
                    throw new Error("lipseste stratul connex");
                }
                this.mapCtrl.animateTimeRasterData.sourceVectorLayer = sourceLayer;
                //extract source column animate_time_raster_column
                let sourceColumnName = this.mapCtrl.userSettingsSrvs.isAuthForOptionFullInfo(authOpt.animate_time_raster_column, layer.name, authType.layer);
                if (sourceColumnName === undefined || sourceColumnName.descriere === undefined || sourceColumnName.descriere === '') {
                    throw new Error("lipseste nume strat connex");
                }
                this.mapCtrl.animateTimeRasterData.sourceVectorColumn = sourceColumnName.descriere;
                //test refresh
                let extent = this.mapCtrl.map.getView().calculateExtent(this.mapCtrl.map.getSize());
                let layersrc = (this.mapCtrl.animateTimeRasterData.sourceVectorLayer.internalLayer as VectorLayer<any>).getSource();
                this.mapCtrl.mapOlInit.loadVectorFromServer(this.mapCtrl.animateTimeRasterData.sourceVectorLayer, layersrc)(extent);
            } catch (reason) {
                this.$log.error("Eroare animare strat temporal ", getExMessage(reason));
                this.animateCancel();
                this.isInitingFlag = false;
            }
        }


        public sendEventLoadRefEnd(layer:ILayer){
            if(layer.id === this.mapCtrl.animateTimeRasterData.sourceVectorLayer.id){
                this.mapCtrl.interalMessageService.broadcast("sendRefLalyerLoadEnd");
            }
        }

        public onEventLoadRefEnd(){
            if (this.isInitingFlag == false){
                return;
            }
             //get the steps from connected layer
             let features = (this.mapCtrl.animateTimeRasterData.sourceVectorLayer.internalLayer as VectorLayer<any>).getSource().getFeatures();
             this.mapCtrl.animateTimeRasterData.steps = [];
             features.forEach((fitem) => {
                 let strDate = fitem.get(this.mapCtrl.animateTimeRasterData.sourceVectorColumn);
                 //
                 if (strDate) {
                     let exists = this.mapCtrl.animateTimeRasterData.steps.filter((item) => item === strDate);
                     if (exists == null || exists.length == 0) {
                         this.mapCtrl.animateTimeRasterData.steps.push(strDate);
                     }
                 }
             });
             //
             this.setAnimateData();
        }

        public setSourceRasterTimeParam(timeValue: string) {
            //animate_time_raster_inwviewparams
            if (this.mapCtrl.animateTimeRasterData.sourceRasterLayer.internalLayer) {
                this.mapCtrl.animateTimeRasterData.info = timeValue;
                let source = (this.mapCtrl.animateTimeRasterData.sourceRasterLayer.internalLayer as TileLayer<any>).getSource() as TileWMSSource;
                let params = source.getParams();
                if (this.mapCtrl.userSettingsSrvs.isAuthForOption(authOpt.animate_time_raster_inwviewparams, this.mapCtrl.animateTimeRasterData.sourceRasterLayer.name, authType.layer)){
                    let tmpvpar = [{layer: this.mapCtrl.animateTimeRasterData.sourceRasterLayer.name, set: `time_asvparam:${timeValue}`}];
                    this.mapCtrl.cqlFilterService.parseViewParamsString(tmpvpar);
                    this.mapCtrl.loadAllLayersCQLFilter();
                }
                params['time'] = timeValue;//time=2009-11-01
                source.updateParams(params);
                source.changed();
            }
        }

        public initSourceExisting():boolean{
            const source = (this.mapCtrl.animateTimeRasterData.sourceRasterLayer.internalLayer as TileLayer<any>).getSource() as TileWMSSource;
            const params = source.getParams();
            const timeValue = params['time'] || null;
            if (timeValue && timeValue.length > 0) {
                const index = this.mapCtrl.animateTimeRasterData.steps.indexOf(timeValue);
                if (index >= 0 && index <= this.mapCtrl.animateTimeRasterData.steps.length) {
                    this.mapCtrl.animateTimeRasterData.index = index;
                    this.mapCtrl.animateTimeRasterData.info = timeValue;
                    return true;
                }
            }
            return false;
        }

        public onChangeComboTime() {
            //
            const index = this.mapCtrl.animateTimeRasterData.steps.indexOf(this.mapCtrl.animateTimeRasterData.info);
            if (index >= 0 && index <= this.mapCtrl.animateTimeRasterData.steps.length){
                this.mapCtrl.animateTimeRasterData.index = index;
            }
            this.setSourceRasterTimeParam(this.mapCtrl.animateTimeRasterData.info)
            //
        }

        public animateCancel = () => {
            this.animateStop();
            this.mapCtrl.showMainMenu = true;
            this.mapCtrl.timeRasterAnimate = false;
            clearInterval(this.resInterval);

            this.mapCtrl.animateTimeRasterData = {
                //timesource
                sourceVectorColumn: null,
                sourceVectorLayer: null,
                sourceRasterLayer: null,
                //
                index: 0,
                isAnimating: false,
                ticks: 0,
                speed: 3,
                maxSpeed: 11,
                minSpeed: 1,
                sliderValue: 3,
                steps: [],
                startPointIndex: 0,
                info: '',
                startInterval: null,
                endInterval: null,
                selectSteps: [],
                startSteps: [],
                endSteps: []
            }
        }

        //todo
        private configAnimate():void {
           
             this.resInterval = setInterval(
                 () => {
                     try {
                        let minIndex = this.mapCtrl.animateTimeRasterData.steps.indexOf(this.mapCtrl.animateTimeRasterData.startInterval);
                        let maxIndex = this.mapCtrl.animateTimeRasterData.steps.indexOf(this.mapCtrl.animateTimeRasterData.endInterval);
                         //this.mapCtrl.animateTimeRasterData.ticks++;
                         if (this.mapCtrl.animateTimeRasterData.isAnimating) {
                             //simulate delay by executing on different ticks
                             if (this.mapCtrl.animateTimeRasterData.ticks > this.mapCtrl.animateTimeRasterData.sliderValue) {
                                 if (this.mapCtrl.animateTimeRasterData.index >= maxIndex) {
                                     this.mapCtrl.animateTimeRasterData.index = minIndex;
                                     this.setSourceRasterTimeParam(this.mapCtrl.animateTimeRasterData.steps[this.mapCtrl.animateTimeRasterData.index]);
                                 } else {
                                     this.animateStepForward();
                                 }
                                 //reset ticks
                                 this.mapCtrl.animateTimeRasterData.ticks = 0;
                             }
                             this.mapCtrl.animateTimeRasterData.ticks++;
                         }
                         
                     } catch (reason) {
                         this.$log.error("eroare animare time raster " , getExMessage(reason));
                     }
                }, 1000
            )
        }

        //todo
        public setRouteStartPointFromIndex() {
                 }

        public animatePlay = () => {
            this.mapCtrl.animateTimeRasterData.ticks = this.mapCtrl.animateTimeRasterData.sliderValue
            this.mapCtrl.animateTimeRasterData.isAnimating = true;
        }

        public animatePause = () => {
            this.pauseOrStop(false);
        }

        public animateStop = () => {
            this.pauseOrStop(true);
        }

        public animateStepBack = () => {
            let minIndex = this.mapCtrl.animateTimeRasterData.steps.indexOf(this.mapCtrl.animateTimeRasterData.startInterval);
            
            if (this.mapCtrl.animateTimeRasterData.index > minIndex) {
                this.mapCtrl.animateTimeRasterData.index--;
            }
            this.setSourceRasterTimeParam(this.mapCtrl.animateTimeRasterData.steps[this.mapCtrl.animateTimeRasterData.index]);
        }

        public animateStepForward = () => {
            let maxIndex = this.mapCtrl.animateTimeRasterData.steps.indexOf(this.mapCtrl.animateTimeRasterData.endInterval);
            if (this.mapCtrl.animateTimeRasterData.index < maxIndex) {
                this.mapCtrl.animateTimeRasterData.index++;
            }
            this.setSourceRasterTimeParam(this.mapCtrl.animateTimeRasterData.steps[this.mapCtrl.animateTimeRasterData.index]);
        }

        private pauseOrStop = stop => {
            this.mapCtrl.animateTimeRasterData.isAnimating = false;
            this.mapCtrl.animateTimeRasterData.ticks = 0;
            if (stop) {
                this.mapCtrl.animateTimeRasterData.index = 
                this.mapCtrl.animateTimeRasterData.steps.indexOf(this.mapCtrl.animateTimeRasterData.startInterval);
            }
        }

        private setInitialSteps(){
            this.mapCtrl.animateTimeRasterData.startSteps.length = 0;
            this.mapCtrl.animateTimeRasterData.endSteps.length = 0;
            this.mapCtrl.animateTimeRasterData.steps.forEach(sitem=>{
                this.mapCtrl.animateTimeRasterData.startSteps.push(sitem);
                this.mapCtrl.animateTimeRasterData.endSteps.push(sitem);
                this.mapCtrl.animateTimeRasterData.selectSteps.push(sitem);
            })
            this.mapCtrl.animateTimeRasterData.startInterval = this.mapCtrl.animateTimeRasterData.steps[0] || null;
            
            this.mapCtrl.animateTimeRasterData.endInterval = this.mapCtrl.animateTimeRasterData.steps[this.mapCtrl.animateTimeRasterData.steps.length - 1] || null;

        }

        public onChangeComboStartInterval(value){
            this.mapCtrl.animateTimeRasterData.selectSteps.length = 0;
            this.mapCtrl.animateTimeRasterData.endSteps.length = 0;
            this.mapCtrl.animateTimeRasterData.steps.forEach(sitem => {
                if (sitem >= value) {
                    this.mapCtrl.animateTimeRasterData.endSteps.push(sitem);
                }
                if (sitem >= value && sitem <= this.mapCtrl.animateTimeRasterData.endInterval) {
                    this.mapCtrl.animateTimeRasterData.selectSteps.push(sitem);
                }
            })
            if (value > this.mapCtrl.animateTimeRasterData.info){
                this.mapCtrl.animateTimeRasterData.info = value
                this.onChangeComboTime();
            }
        }
        
        public onChangeComboEndInterval(value){
            this.mapCtrl.animateTimeRasterData.selectSteps.length = 0;
            this.mapCtrl.animateTimeRasterData.startSteps.length = 0;
            this.mapCtrl.animateTimeRasterData.steps.forEach(sitem => {
                if (sitem <= value) {
                    this.mapCtrl.animateTimeRasterData.startSteps.push(sitem);
                }
                if (sitem <= value && sitem >= this.mapCtrl.animateTimeRasterData.startInterval) {
                    this.mapCtrl.animateTimeRasterData.selectSteps.push(sitem);
                }
            })
            if (value < this.mapCtrl.animateTimeRasterData.info){
                this.mapCtrl.animateTimeRasterData.info = value;
                this.onChangeComboTime();
            }
        }

        private onChangeSelectedSteps(value){

        }
    }
